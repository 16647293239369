import React from "react";

import { Icon, Table, Popup } from "semantic-ui-react";

const FeaturesTable = () => (
  <Table celled textAlign="center" unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={8}></Table.HeaderCell>
        <Table.HeaderCell width={4}>Salesforce Identity</Table.HeaderCell>
        <Table.HeaderCell width={4}>CYM Identity</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign="left">Authorization Servers</Table.Cell>
        <Table.Cell>
          <span>1 per community</span>
        </Table.Cell>
        <Table.Cell>
          <Popup trigger={<span>multiple per community</span>}>
            You can define as many Realms as you need per community
          </Popup>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Custom Scopes</Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Custom claims</Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Machine to Machine</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Claim Management</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can choose exactly which claims are sent to APIs and control what
            User Data is shared
          </Popup>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">JWT Access Tokens</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Icon name="check circle outline" size="big" color="green" />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">Custom Policies</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can execute policies per Audience or per Resource
          </Popup>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell textAlign="left">External Authorization Servers</Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Popup
            trigger={
              <Icon name="check circle outline" size="big" color="green" />
            }
          >
            You can build an Application Identity Federation where a Realm relays
            access_token requests to external Authorization Servers
          </Popup>
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default FeaturesTable;

